<script lang="ts">
  import { db } from '../../utils/services';
  import { clickOutside } from '../../utils/clickOutside';
  import { selectedOrg, user, idToken } from '../../utils/store';
  import { getNotificationsContext } from 'svelte-notifications';
  import { doc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
  import Input from '../Input.svelte';

  const { addNotification } = getNotificationsContext();

  export let show = false;

  export let email = '';

  async function createUserRecord(user) {
    // console.log('app: ', $currentApp);
    // console.log('price: ', price);

    const res = await fetch(`/create-user`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${$idToken.replaceAll(/"/g, '')}`,
      },
      credentials: 'include',
      body: JSON.stringify({
        selectedOrg: $selectedOrg,
        user: user,
      }),
    });

    const data = await res.json();
    if (res.ok && data.success) {
      return true;
    } else {
      return false;
    }
  }

  function add() {
    var newUser = {
      createdBy: $user.uid,
      lastUpdatedBy: $user.uid,
      email: email,
      lastUpdatedAt: serverTimestamp(),
    };

    createUserRecord(newUser)
      .then((res) => {
        if (res == true) {
          console.log('sucessfully created user record ');
        } else {
          console.log('failed to create auth user');
        }
      })
      .catch((err) => {
        console.log('failed to create user auth record', err);
      });

    addDoc(collection(db, `org_users/${$selectedOrg.id}/users`), newUser)
      .then((res) => {
        addNotification({
          text: 'Successfully added user!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
        show = false;
      })
      .catch((err) => {
        console.log('Error adding user: ', err);
        addNotification({
          text: 'Failed to add user.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }
</script>

<div
  class="fixed z-50 inset-0 overflow-y-auto"
  on:click_outside={() => (show = false)}
>
  <div
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75" />
    </div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true">&#8203;</span
    >
    <div
      class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm w-full md:max-w-md lg:max-w-lg xl:max-w-2xl"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <!-- Heading -->
            <div class="w-full flex justify-between">
              <h3
                class="text-lg leading-6 font-medium text-gray-900 dark:text-white"
              >
                Add User
              </h3>
              <span
                on:click={() => (show = false)}
                class="hidden md:flex text-gray-700 hover:bg-blue-100 rounded-full w-8 h-8 flex justify-center items-center"
              >
                ×
              </span>
            </div>

            <!-- Body -->
            <div class="mt-2">
              <div class="grid grid-col-12 grid-row-5 mt-4">
                <!-- Row 1 -->
                <div class="row-span-1 flex justify-between w-full">
                  <div class="w-full mr-1 md:mr-4">
                    <Input
                      label="Email"
                      bind:value={email}
                      placeholder="User email"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Footer -->
            <div
              class="mt-6 w-full flex items-center justify-center cursor-pointer"
            >
              <div class="w-1/2 flex justify-end mr-4">
                <span
                  on:click={add}
                  class="flex items-center justify-center text-xs text-socius-alt hover:text-white h-8 w-28 rounded-full hover:bg-socius-alt"
                >
                  Add User
                </span>
              </div>
              <div class="w-1/2 flex justify-start ml-4">
                <span
                  on:click={() => (show = false)}
                  class="flex items-center justify-center text-xs text-red-600 hover:text-gray-900 h-8 w-16 rounded-full hover:bg-red-100"
                >
                  Close
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
