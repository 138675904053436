<script>
  export let org = {};
</script>

<div class="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
  <div class="bg-white rounded-lg w-full shadow hover:shadow-lg">
    <div
      class="flex flex-row justify-between items-center uppercase font-bold text-blue-800 border-b p-4"
    >
      <p class="cursor-pointer" on:click={selectOrg(org)}>
        {org.name}
      </p>

      <!-- <Dropdown
                on:notify={handleClick}
                {org}
                icon={billingIcon}
                items={billingItems}
                headingColorLg="blue-800"
                headingHoverColor="blue-800"
                headingTextSize="sm"
              /> -->
    </div>

    <div
      on:click={selectOrg(org)}
      class="p-6 text-grey-darker flex flex-col hover:bg-blue-100 duration-4 cursor-pointer"
    >
      <!-- <img
        src="https://picsum.photos/300/300"
        alt="company"
        class="w-32 flex self-center rounded-full shadow-lg mb-6"
      /> -->
      <p class="font-bold text-sm uppercase mb-2 text-blue-darker">
        Information
      </p>
      <p>
        <span class="text-grey-darker text-xs">
          <p>Email: {org.email}</p>
          <p>Description: {org.description}</p>
          <p>Address: {org.address}</p>
          <p>Users: {org.users.length}</p>
        </span>
      </p>
    </div>
  </div>
</div>
