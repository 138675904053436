<script lang="ts">
  import { onMount } from 'svelte';
  import tippy from 'tippy.js';

  export let showTooltip = false;
  export let text = 'A tooltip!';
  let anchor;
  let tooltip;
  $: if (tooltip) showTooltip ? tooltip.show() : tooltip.hide();

  onMount(() => {
    tooltip = tippy(anchor, {
      content: text,
      animation: 'shift-toward',
      duration: [null, 0],
      trigger: 'manual',
    });
  });
</script>

<div bind:this={anchor} id="root">
  <slot />
</div>

<svelte:head>
  <link rel="stylesheet" href="https://unpkg.com/tippy.js@6/dist/tippy.css" />
  <link
    rel="stylesheet"
    href="https://unpkg.com/tippy.js@6/animations/shift-toward.css"
  />
</svelte:head>
