<script lang="ts">
  import {
    organizations,
    selectedOrg,
    selectedOrgId,
    createNew,
    loading,
    states,
    idToken,
    user,
    orgUsers,
  } from '../../utils/store';
  import { onMount } from 'svelte';
  import { redirect } from '@sveltech/routify';
  import UserTable from '../../components/UserTable.svelte';
  import Select from '../../components/Select.svelte';
  import NewUserModal from '../../components/modals/NewUserModal.svelte';
  import Tooltip from '../../components/Tooltip.svelte';
  import { getAllApps, getOrgUsers } from '../../utils/utils';
  import { getNotificationsContext } from 'svelte-notifications';
  import { Shadow } from 'svelte-loading-spinners';
  import { doc, updateDoc } from 'firebase/firestore';
  import { db } from '../../utils/services';
  import DeleteModal from '../../components/modals/DeleteModal.svelte';
  import Heading from '../../components/Heading.svelte';
  import Input from '../../components/Input.svelte';

  const { addNotification } = getNotificationsContext();

  $: disabled = true;
  let modal = false;
  let showTooltip = false;
  let showTooltipSave = false;
  let loadingBilling = false;
  let name = $selectedOrg !== undefined ? $selectedOrg.name : '';
  let address = $selectedOrg !== undefined ? $selectedOrg.address : '';
  let city = $selectedOrg !== undefined ? $selectedOrg.city : '';
  let state = $selectedOrg !== undefined ? $selectedOrg.state : '';
  let zip = $selectedOrg !== undefined ? $selectedOrg.zip : '';
  let repName = $selectedOrg !== undefined ? $selectedOrg.repName : '';
  let repEmail = $selectedOrg !== undefined ? $selectedOrg.repEmail : '';

  $: if ($selectedOrg) setValues();

  const getOptionLabel = (option) => option.name;
  const getSelectionLabel = (option) => option.name;
  const optionIdentifier = 'id';
  const getOptionLabelStates = (option) => `${option.full} - ${option.short}`;
  const getSelectionLabelStates = (option) => option.short;
  const optionIdentifierStates = 'short';

  let deleteUserModal = false;
  let currentEntry = {};

  onMount(() => {
    if ($selectedOrg === undefined && !$loading) {
      $createNew = true;
      $redirect('/organizations');
    }
  });

  function setValues() {
    name = $selectedOrg.name;
    address = $selectedOrg.address;
    city = $selectedOrg.city;
    state = $selectedOrg.state;
    zip = $selectedOrg.zip;
    repName = $selectedOrg.repName;
    repEmail = $selectedOrg.repEmail;
  }

  function handleSelect(event) {
    // console.log('selected item', event.detail);
    $selectedOrg = event.detail;
    $selectedOrgId = $selectedOrg.id; // so the selection will persist

    name = $selectedOrg.name;
    address = $selectedOrg.address;
    city = $selectedOrg.city;
    state = $selectedOrg.state;
    zip = $selectedOrg.zip;
    repName = $selectedOrg.repName;
    repEmail = $selectedOrg.repEmail;

    getAllApps();
    getOrgUsers($selectedOrgId);
  }

  function getState(event) {
    state = event.detail.short;
  }

  function redirectToBilling() {
    loadingBilling = true;
    getBillingPortalURL().then((data) => {
      loadingBilling = false;
    });
  }

  async function getBillingPortalURL() {
    fetch('/create-portal-link', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${$idToken.replaceAll(/"/g, '')}`,
      },
      body: JSON.stringify({
        returnUrl: window.location.origin + '/business',
        orgID: $selectedOrgId,
        userID: $user.uid,
      }),
    })
      .then((res) => {
        if (res.ok == true) {
          res
            .json()
            .then((data) => {
              console.log(data);
              window.open(data['url'], '_blank');
              return data['url'];
            })
            .catch((err) => {
              console.log('error opening billing portal', err);
            });
        } else {
          console.log(
            `error getting billing portal url with [${res.status}]: ${res.statusText}`
          );
          addNotification({
            text: 'Failed to open billing portal.',
            position: 'bottom-center',
            type: 'error',
            removeAfter: 2000,
          });
        }
      })
      .catch((err) => {
        console.log('error getting billing portal url', err);
      });
  }

  function createNewBusiness() {
    $createNew = true;
    $redirect('/organizations');
  }

  function addUser() {
    modal = true;
  }

  function deleteUser(event) {
    currentEntry = event.detail;
    // console.log(currentEntry);
    deleteUserModal = true;
  }

  function removeUser(event) {
    console.log(`removing user: ${currentEntry.email}`);
    loadingBilling = true;
    fetch('/remove-user-from-org', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${$idToken.replaceAll(/"/g, '')}`,
      },
      body: JSON.stringify({
        selectedOrg: $selectedOrg,
        user: currentEntry,
      }),
    })
      .then((res) => {
        loadingBilling = false;
        if (res.ok == true) {
          res.json().then((data) => {
            if (data['success']) {
              addNotification({
                text: 'Successfully removed user!',
                position: 'bottom-center',
                type: 'success',
                removeAfter: 2000,
              });
            } else {
              addNotification({
                text: 'Failed to remove user.',
                position: 'bottom-center',
                type: 'error',
                removeAfter: 2000,
              });
            }
          });
        } else {
          console.log(
            `error removing user with [${res.status}]: ${res.statusText}`
          );
          addNotification({
            text: 'Failed to remove user.',
            position: 'bottom-center',
            type: 'error',
            removeAfter: 2000,
          });
        }
      })
      .catch((err) => {
        loadingBilling = false;
        console.log('error removing user', err);
        addNotification({
          text: 'Failed to remove user.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }

  function cancel() {
    setValues();
    disabled = true;
  }

  function saveOrgInfo() {
    $selectedOrg.name = name;
    $selectedOrg.address = address;
    $selectedOrg.city = city;
    $selectedOrg.state = state;
    $selectedOrg.zip = zip;
    $selectedOrg.repName = repName;
    $selectedOrg.repEmail = repEmail;

    const docRef = doc(db, `organizations/${$selectedOrg.id}`);
    updateDoc(docRef, $selectedOrg)
      .then((res) => {
        addNotification({
          text: 'Successfully updated business information!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
        disabled = true;
      })
      .catch((err) => {
        console.log('failed to update business information', err);
        addNotification({
          text: 'Failed to update business information.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }
</script>

{#if modal}
  <!-- <NewUserModal on:notify={saveUsers} bind:show={modal} /> -->
  <NewUserModal bind:show={modal} />
{/if}

{#if deleteUserModal}
  <DeleteModal bind:show={deleteUserModal} on:notify={removeUser} />
{/if}

{#if loadingBilling}
  <div class="w-full h-full flex justify-center items-center">
    <Shadow size="60" unit="px" duration="1s" color="#6ea6f3" />
  </div>
{:else if !$loading}
  {#if $organizations && $selectedOrg}
    <Heading
      heading="Business"
      subheading="Manage your business and billing."
    />

    <div class="mb-4">
      <span class="text-small text-gray-600 dark:text-gray-300">Business</span>

      <!-- <Select key={(org) => (org && org.name) || ''} label="Organization">
      {#each $organizations as org (org.name)}
        <Option value={org.id}>{org.name}</Option>
      {/each}
    </Select> -->

      <div class="flex">
        <div class="themed cursor-pointer w-48 border-b border-gray-300">
          <Select
            items={$organizations}
            showIndicator={true}
            isClearable={false}
            {getOptionLabel}
            {getSelectionLabel}
            {optionIdentifier}
            on:notify={handleSelect}
            bind:selectedValue={$selectedOrg}
          />
        </div>
        <span
          class="text-small text-socius-secondary ml-4 pt-1 cursor-pointer"
          on:click={createNewBusiness}>+Add New Business</span
        >
      </div>
    </div>

    <div
      class="shadow-lg rounded bg-white dark:bg-gray-800 dark:text-white p-6 mb-8"
    >
      <div class="text-sm mb-8">
        <div class="flex justify-between items-center">
          <p class="text-2xl font-bold mb-4">General</p>
          {#if disabled}
            <div class="mr-8">
              <Tooltip text="Edit business information" {showTooltip}>
                <i
                  class="flex justify-center items-center cursor-pointer text-gray-600 fas fa-edit"
                  on:click={() => (disabled = false)}
                  on:mouseenter={() => (showTooltip = true)}
                  on:mouseleave={() => (showTooltip = false)}
                />
              </Tooltip>
            </div>
          {:else}
            <div class="flex">
              <Tooltip text="Save" showTooltip={showTooltipSave}>
                <i
                  class="flex justify-center items-center cursor-pointer text-socius-alt fas fa-save"
                  on:click={saveOrgInfo}
                  on:mouseenter={() => (showTooltipSave = true)}
                  on:mouseleave={() => (showTooltipSave = false)}
                />
              </Tooltip>
              <span class="ml-4 mr-8">
                <Tooltip text="Cancel" {showTooltip}>
                  <i
                    class="flex justify-center items-center cursor-pointer text-red-500 fas fa-ban"
                    on:click={cancel}
                    on:mouseenter={() => (showTooltip = true)}
                    on:mouseleave={() => (showTooltip = false)}
                  />
                </Tooltip>
              </span>
            </div>
          {/if}
        </div>
        <p>Manage information for <b>{$selectedOrg.name}</b></p>
      </div>

      <div class="flex gap-2 md:gap-16 mb-12">
        <div class="w-full md:w-1/2 pr-0 md:pr-16">
          <Input
            label="Business Name"
            bind:value={name}
            {disabled}
            placeholder="Acme Co."
          />
          <Input
            label="Business Address"
            bind:value={address}
            {disabled}
            placeholder="Acme Co."
          />

          <div class="w-full flex gap-4">
            <div class="w-1/3">
              <Input
                label="City"
                bind:value={city}
                {disabled}
                placeholder="Albuquerque"
              />
            </div>

            <div class="w-1/3">
              <label
                for="state"
                class="text-small text-gray-700 dark:text-gray-300"
              >
                State
              </label>
              <Select
                {disabled}
                items={states}
                showIndicator={true}
                isClearable={false}
                getOptionLabel={getOptionLabelStates}
                getSelectionLabel={getSelectionLabelStates}
                optionIdentifier={optionIdentifierStates}
                bind:selectedValue={state}
                on:notify={getState}
                placeholder="NM"
              />
            </div>

            <div class="w-1/3">
              <Input
                label="Zipcode"
                bind:value={zip}
                {disabled}
                placeholder="87104"
              />
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/2 pr-0 md:pr-20">
          <Input
            label="Business ID"
            bind:value={$selectedOrgId}
            disabled="true"
            placeholder=""
          />

          <Input
            label="Representative Name"
            bind:value={repName}
            {disabled}
            placeholder="George P. Burdell"
          />

          <Input
            label="Representative Email"
            bind:value={repEmail}
            {disabled}
            placeholder="gpburdell@email.com"
          />
        </div>
      </div>

      <button
        class="w-48 rounded uppercase px-8 py-4 flex items-center justify-center text-xs text-white font-bold bg-socius-alt shadow-lg hover:shadow-xl hover:bg-socius-primary"
        on:click={redirectToBilling}
        target="_blank"
      >
        Manage Billing
      </button>
    </div>

    <div
      class="shadow-lg rounded bg-white dark:bg-gray-800 dark:text-white p-6 mb-8"
    >
      <div class="text-sm mb-4">
        <p class="text-2xl font-bold mb-4">Users</p>
        <p>Manage userbase for <b>{$selectedOrg.name}</b></p>
        <p class="text-small text-red-500">
          Removing users here will remove them from all apps.
        </p>
      </div>

      <button
        class="w-48 mb-6 rounded uppercase px-8 py-4 flex items-center justify-center text-xs text-white font-bold bg-socius-alt shadow-lg hover:shadow-xl hover:bg-socius-primary"
        on:click={addUser}
        target="_blank"
      >
        Add User
      </button>

      <UserTable users={$orgUsers} on:delete={deleteUser} />
    </div>
  {/if}
{/if}

<style>
  .themed {
    /* CSS variables below are used by the svelte-select component for custom styling */
    --height: 33px;
    --border: 0px solid transparent;
    /* --indicatorColor: #3b82f6; */
    --indicatorTop: 5px;
    --itemIsActiveBG: #6ea6f3;
    --itemIsActiveColor: #fff;
    --itemHoverBG: #dbeafe;
    /* --placeholderColor: #dbeafe; */
    --inputFontSize: 0.75rem;
    --background: transparent;
    --disabledBackground: transparent;
    --disabledPlaceholderColor: var(text-gray-300);
    --placeholderColor: var(text-gray-500);
  }
  .themed :global(*) {
    text-align: left;
    font-size: 0.75rem;
  }
  .text-small {
    font-size: 0.65rem;
  }
</style>
