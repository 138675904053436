<script lang="ts">
  import Navbar from '../components/nav/Navbar.svelte';
  import MobileSidenav from '../components/nav/MobileSidenav.svelte';
  import Auth from '../components/auth/Auth.svelte';
  import EmailVerification from '../components/auth/EmailVerification.svelte';
  import Notifications from 'svelte-notifications';
  import {
    user,
    claims,
    organizations,
    createNew,
    idToken,
    emailVerified,
    selectedOrgId,
    userSettings,
    darkMode,
  } from '../utils/store';
  import {
    loadOrganizations,
    getOrgUsers,
    loadUserSettings,
  } from '../utils/utils';
  import { getApp } from '@firebase/app';
  import {
    getAuth,
    onAuthStateChanged,
    signOut,
    setPersistence,
    browserSessionPersistence,
    signInWithCustomToken,
  } from 'firebase/auth';

  $: try {
    $darkMode = $userSettings.portal.darkMode;
  } catch (error) {
    darkMode.set(!matchMedia('(prefers-color-scheme: light)').matches);
  }

  const auth = getAuth(getApp());

  function checkSessionCookie() {
    fetch(`/auth/status`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((result) => {
        result
          .json()
          .then((data) => {
            signUserInWithCustomToken(data['custom_token']);
          })
          .catch((err) => {
            console.log('error getting text', err);
          });

        if (result.ok == true) {
          console.log('successfully got cookie');
        } else {
          console.log(`error with [${result.status}]: ${result.statusText}`);
        }
      })
      .catch((err) => {
        console.log('error checking cookie', err);
      });
  }

  async function signUserInWithCustomToken(token: string) {
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        signInWithCustomToken(auth, token)
          .then((userCredential) => {
            // Signed in
            $user = userCredential.user;
            signInAndLoadData();
          })
          .catch((error) => {
            console.log(error);
            console.log(`[${error.code}] ${error.message}`);
          });
      })
      .catch((error) => {
        console.log(`[${error.code}] ${error.message}`);
      });
  }

  function setSessionCookie() {
    let value_or_null = (document.cookie.match(
      /^(?:.*;)?\s*__session\s*=\s*([^;]+)(?:.*)?$/
    ) || [, null])[1];

    // only set cookie if it isn't already set
    if (value_or_null == null) {
      //   console.log('cookie not set, attempting to set...');
      fetch(`/auth/session`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${$idToken.replaceAll(/"/g, '')}`,
        },
        credentials: 'include',
      })
        .then((res) => {
          if (res.ok != true) {
            console.log(
              `error setting cookie. [${res.status}]: ${res.statusText}`
            );
          }
        })
        .catch((err) => {
          console.log('error getting billing portal url', err);
        });
    }
  }

  function signInAndLoadData() {
    let sessionTimeout: ReturnType<typeof setTimeout> = null;

    $user
      .getIdTokenResult(true)
      .then((idTokenResult) => {
        $idToken = JSON.stringify(idTokenResult.token.replaceAll(/"/g, ''));
        setSessionCookie();

        // Create a session timeout which signs the user out.
        // Make sure all the times are in milliseconds!
        const authTime: number = idTokenResult.claims.auth_time * 1000;
        // 1000ms in 1 sec, 60s in 1 min, 60min in 1 hour
        const sessionDuration = 1000 * 60 * 60; //* 24 * 30;
        const millisecondsUntilExpiration =
          sessionDuration - (Date.now() - authTime);
        sessionTimeout = setTimeout(
          () => signOut(auth),
          millisecondsUntilExpiration
        );

        // Set claims
        $claims = idTokenResult.claims;

        loadOrganizations();
        if (!$organizations && !$selectedOrgId) {
          getOrgUsers($selectedOrgId);
        }
        loadUserSettings();
      })
      .catch((error) => {
        console.log('Failed to get claims: ', error);
      });

    // const docRef = doc(db, 'refresh', res.uid);
    // getDoc(docRef).then((document) => {
    //   if (document.exists()) {
    //     let refresh = document.data();
    //     // If the refresh time is up, force a token refresh (grabs new claims)
    //     if (refresh && refresh.refreshTime * 1000 < new Date().getTime()) {
    //       console.log('force refresh token');
    //       res.getIdToken(true).then((idTokenResult) => {
    //         $idToken = JSON.stringify(idTokenResult.replaceAll(/"/g, ''));
    //       });
    //     }
    //   } else {
    //     // doc.data() will be undefined in this case
    //     console.log('No such document for refresh!');
    //   }
    // });
  }

  onAuthStateChanged(auth, (res) => {
    if (res) {
      console.log('Auth state changed');
      $user = res;

      if (res != null) {
        signInAndLoadData();
      }
    } else {
      console.log('No user signed in.');
      checkSessionCookie();
    }
  });
</script>

<div class="dark:bg-gray-800" class:dark={$darkMode}>
  <Notifications>
    {#if $user && $user.emailVerified}
      <div class="grid grid-cols-12">
        {#if !$createNew}
          <div class="hidden md:inline md:col-span-2 dark:bg-gray-800">
            <Navbar />
          </div>
          <!-- <div class="md:pt-20 w-full h-full max-w-screen-xl px-3 pt-16 pb-4 mx-auto text-center"></div> -->
          <MobileSidenav />
          <div
            class="col-span-12 md:col-span-10 w-full p-4 md:p-6 lg:p-8 bg-gray-100 dark:bg-gray-700"
          >
            <slot />
          </div>
        {:else}
          <div class="col-span-12 w-full bg-gray-100 flex dark:bg-gray-700">
            <slot />
          </div>
        {/if}
      </div>
    {:else if $user && !$user.emailVerified}
      <EmailVerification />
    {:else}
      <Auth />
    {/if}
  </Notifications>
</div>
