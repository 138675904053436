<script>
  import { onMount } from 'svelte';
  import { redirect } from '@sveltech/routify';
  import { currentApp } from '../utils/store';

  import Tooltip from './Tooltip.svelte';
  export let app = {};

  let titles = [];
  let descriptions = [];
  let showTooltip = false;

  let yearlyPrice = 0;
  let monthlyPrice = 0;

  function navigate() {
    $currentApp = app;
    $redirect('/products/users');
  }

  onMount(() => {
    // Remove any quotes around the strings, then split them based off the delimiter
    titles = app.stripe_metadata_titles.replace(/['"]+/g, '').split('|');
    descriptions = app.stripe_metadata_descriptions
      .replace(/['"]+/g, '')
      .split('|');

    // console.log('app: ', app);
    for (let i = 0; i < app.prices.length; i++) {
      let price = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: app.prices[i].currency,
      }).format((app.prices[i].unit_amount / 100).toFixed(2));

      if (app.prices[i].interval == 'month') {
        monthlyPrice = price;
      } else if (app.prices[i].interval == 'year') {
        yearlyPrice = price;
      }
    }

    showTooltip = false;
  });
</script>

<!-- on:click={navigate} -->
<div class="my-1 px-1 w-full lg:my-4 lg:px-4 h-full">
  <div
    class="bg-white dark:bg-gray-800 dark:text-white rounded-lg w-full shadow hover:shadow-lg duration-4"
  >
    <div class="flex flex-row justify-between items-center p-4">
      <div class="">
        <p class="font-bold text-2xl">{app.name}</p>
        <p class="text-xs my-2" class:text-transparent={!app.description}>
          {app.description}
        </p>
      </div>

      <Tooltip text="Sign up for a free trial" {showTooltip}>
        <button
          class="rounded-lg uppercase px-4 md:px-8 py-4 flex items-center text-xs text-white font-bold bg-socius-alt shadow-lg"
          on:click={navigate}
          target="_blank"
          on:mouseenter={() => (showTooltip = true)}
          on:mouseleave={() => (showTooltip = false)}
        >
          Try for Free <i class="ml-2 fas fa-arrow-right" />
        </button>
      </Tooltip>
    </div>

    <div class="px-4 pb-6 mb-4">
      <div class="grid grid-col-12 grid-row-5 mt-4">
        <!-- Row 1 -->
        <div class="row-span-1 flex justify-between w-full">
          <div class="w-1/2 mr-1 md:mr-4">
            {#each titles as title, i}
              <div class="text-sm">
                <p class="font-bold">{title}</p>
                <p class="mb-4">{descriptions[i]}</p>
              </div>
            {/each}
          </div>
          {#if app.images.length > 0}
            <div class="w-1/2 ml-1 md:ml-4">
              <img
                src={app.images[0]}
                alt={app.name}
                class="flex self-center flex justify-center items-center shadow-lg"
              />
            </div>
          {/if}
        </div>
        <div class="row-span-1 flex justify-between w-full">
          <div class="pt-6">
            <p class="text-sm mb-2">
              <span
                class="text-2xl text-socius-primary dark:text-socius-secondary mr-2"
                >{yearlyPrice}</span
              >
              <span class="">Per User, Per Year</span>
            </p>
            <p class="text-xs italic">
              <span class="">or {monthlyPrice} per user if paid monthly</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
