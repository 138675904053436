<script context="module" lang="ts">
  let ui: firebaseui.auth.AuthUI;
  declare const firebaseui: typeof import('./firebaseui');
  declare const firebase: typeof import('firebase/compat').default;
</script>

<script lang="ts">
  import type { User } from 'firebase/auth';
  import { onMount, createEventDispatcher } from 'svelte';
  import type { FirebaseOptions } from 'firebase/app';
  export let firebaseConfig: FirebaseOptions;

  const dispatch = createEventDispatcher<{
    success: string | null;
    updateuserdata: { user: User; isNewUser: boolean };
  }>();
  let loading = true;
  let container: HTMLDivElement;

  const loadScript = (url: string) =>
    new Promise<Event>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });

  const loadScriptOnce = (() => {
    const loaded = [];
    return async function (url) {
      if (!loaded.includes(url)) {
        loaded.push(url);
        await loadScript(url);
        return true;
      } else {
        return true;
      }
    };
  })();

  onMount(async () => {
    await loadScriptOnce(
      'https://www.gstatic.com/firebasejs/9.5.0/firebase-app-compat.js'
    );
    await loadScriptOnce(
      'https://www.gstatic.com/firebasejs/9.5.0/firebase-auth-compat.js'
    );
    if (firebase && firebase.apps && firebase.apps.length === 0) {
      // const firebaseApp = getApp(); // oddly enough this works in local dev but returns undefined when used in an installed package - I'm not sure why so I'm requiring users to pass the config into this component manually until someone can help me understand this problem.
      // firebase.initializeApp(firebaseApp.options);
      firebase.initializeApp(firebaseConfig);
    }
    await loadScriptOnce(
      `https://www.gstatic.com/firebasejs/ui/6.0.0/firebase-ui-auth__en.js`
    );
    initAuthUi();
  });
  function initAuthUi() {
    const authUIConfig = {
      signInSuccessUrl: `${process.env.SIGNINSUCCESSURL}`,
      signInOptions: [
        // new EmailAuthProvider(),
        // new GoogleAuthProvider(),
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      signInFlow: 'popup',
      tosUrl: 'https://sociusco.com/tos',
      privacyPolicyUrl: 'https://sociusco.com/privacy-policy',

      //   callbacks: {
      //     signInSuccessWithAuthResult: function (authResult) {
      //       const user = authResult.user as User;
      //       // var credential = authResult.credential;
      //       const isNewUser = authResult.additionalUserInfo.isNewUser;
      //       // const providerId = authResult.additionalUserInfo.providerId; // password or google.com
      //       // var operationType = authResult.operationType; //signIn
      //       dispatch('updateuserdata', { user, isNewUser });
      //       dispatch('success', 'auth success');
      //       // Do something with the returned AuthResult.
      //       // Return type determines whether we continue the redirect automatically
      //       // or whether we leave that to developer to handle.
      //       return true;
      //     },
      //     signInFailure: function (error) {
      //       // Some unrecoverable error occurred during sign-in.
      //       // Return a promise when error handling is completed and FirebaseUI
      //       // will reset, clearing any UI. This commonly occurs for error code
      //       // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
      //       // occurs. Check below for more details on this.
      //       return handleUIError(error);
      //     },
      //     uiShown: () => (loading = false),
      //   },
    };

    if (ui) {
      ui.reset();
      ui.start(container, authUIConfig);
    } else {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
      ui.start(container, authUIConfig);
    }
  }
  async function handleUIError(
    error: firebaseui.auth.AuthUIError
  ): Promise<void> {
    console.error(error);
    window.location.replace('/');
  }
</script>

<div bind:this={container} />
