<script>
  import NavLink from './NavLink.svelte';
  import { routes } from '../../utils/store.ts';
  import LogoutModal from '../LogoutModal.svelte';
  import { selectedOrg } from '../../utils/store';

  let logout = false;
</script>

{#if logout}
  <LogoutModal bind:logout />
{/if}

<div
  class="h-screen bg-gray-100 md:bg-white w-full flex flex-col md:dark:bg-gray-800"
>
  <!-- Header -->
  <div
    class="flex items-center justify-start md:justify-center px-0 py-4 md:py-0 md:h-24"
  >
    <img src="/socius-logo.svg" class="inline h-14 mr-4" alt="Socius Logo" />
    <span class="hidden lg:inline dark:text-white"> Portal </span>
  </div>

  <!-- Links -->
  {#each routes as route}
    <NavLink heading={route.heading} icon={route.icon} route={route.route} />
  {/each}

  <div class="flex-grow" />

  <!-- Bottom -->
  <div class="mb-6">
    <!-- <NavLink heading="Settings" icon="fa fa-cog" route="/settings" /> -->
    {#if $selectedOrg}
      <span
        class="w-full pl-4 pr-4 lg:pr-6 py-2 hidden md:flex text-xs text-gray-500 flex items-center justify-center"
        >{$selectedOrg.name}</span
      >
    {/if}
    <div
      on:click={() => (logout = true)}
      class="hidden md:block md:pl-4 md:pr-6 py-2 text-sm text-gray-700 dark:text-gray-300 flex items-center justify-center md:justify-start hover:bg-gray-200 dark:hover:bg-gray-700 hover:text-socius-secondary"
    >
      <i class="fa fa-sign-out-alt md:pl-2 md:pr-4" aria-hidden="true" />

      <!-- <img
        class="h-6 w-6 rounded-full"
        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
        alt="" /> -->

      <span> Logout </span>
    </div>
  </div>
</div>
