<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  let headers = ['Email', '($) Price Per Period', ''];
  export let users;
  export let price;
</script>

{#if users.length > 0}
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="px-4 border-none">
      <tr>
        {#each headers as header, i}
          <th
            scope="col"
            class:w-50={i == 0}
            class="py-3 pl-2 text-left smaller-text text-gray-600 dark:text-gray-300 dark:font-medium font-light"
          >
            {header}
          </th>
        {/each}
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 border-none">
      {#each users as d, i}
        <tr
          class="border-none cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-700 mb-2"
        >
          <td class="px-2 whitespace-nowrap">
            <div class="flex items-center">
              <div
                class="small-text font-medium text-gray-900 dark:text-gray-300"
              >
                {d.email}
              </div>
            </div>
          </td>

          <td class="px-2 whitespace-nowrap h-6">
            <div class="flex items-center">
              <div
                class="small-text font-medium text-gray-900 dark:text-gray-300 flex items-center"
              >
                {price}
              </div>
            </div>
          </td>

          <td class="px-2 whitespace-nowrap">
            <div class="flex items-center justify-center">
              <div>
                <div
                  class="text-xs font-medium text-red-500 flex items-center justify-center"
                  on:click={() => dispatch('delete', d)}
                >
                  <i class="fas fa-trash" />
                </div>
              </div>
            </div>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
{/if}

<style>
  .smaller-text {
    font-size: 0.5rem;
  }

  .small-text {
    font-size: 0.65rem;
  }
</style>
