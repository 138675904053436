<script>
  export let heading = '';
  export let subheading = '';
</script>

<div class="text-sm mb-6 dark:text-white flex justify-between items-center">
  <div>
    <p class="text-5xl font-bold mb-4">{heading}</p>
    {#if subheading != ''}
      <p>{subheading}</p>
    {/if}
  </div>
  <slot name="button" />
</div>
