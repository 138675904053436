<script>
  import { user } from '../../utils/store';
  import { sendVerificationEmail } from '../../utils/auth';
  import { getApp } from '@firebase/app';
  import { getAuth, signOut } from 'firebase/auth';
  import { redirect } from '@sveltech/routify';

  const app = getApp();
  const firebaseAuth = getAuth(app);

  let emailSuccess = false;

  function sendEmail() {
    emailSuccess = sendVerificationEmail()
      .then((resp) => {
        addNotification({
          text: 'Successfully resent verification email!',
          position: 'bottom-center',
          type: 'success',
          removeAfter: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        addNotification({
          text: 'Failed to resend verification email.',
          position: 'bottom-center',
          type: 'error',
          removeAfter: 2000,
        });
      });
  }

  function callAuthLogout() {
    console.log('calling auth function to logout');
    fetch(`/auth/logout`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((result) => {
        if (result.ok == true) {
          console.log('successfully logged out from auth function');
        } else {
          console.log(`error with [${result.status}]: ${result.statusText}`);
        }

        signout();
      })
      .catch((err) => {
        console.log('error logging out', err);
        signout();
      });
  }

  function signout() {
    signOut(firebaseAuth).then(
      function () {
        console.log('Signed Out');
        localStorage.removeItem('selectedOrgId');
        localStorage.removeItem('idToken');
        $user = undefined;
        // authUI.reset();
        $redirect('/');
      },
      function (error) {
        console.error('Sign Out Error', error);
      }
    );
  }
</script>

<div
  class="flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
>
  <div class="max-w-md w-full">
    <div class="mb-4 text-center">
      <h2 class="mt-6 text-3xl font-extrabold text-gray-900">
        Verify your email address
      </h2>
      <p class="mt-8 text-sm text-gray-600">
        You're almost done! A verification message has been sent to
        <span class="font-bold text-gray-800">${$user.email}</span>
      </p>

      <p class="text-xs mt-4">
        Just check your email and follow the link to verify your account.
        Entered the wrong address?
        <span class="cursor-pointer text-socius-primary"
          >Change your email.</span
        >
      </p>

      <hr class="mt-16" />
      <p class="mt-4 small-text">
        Can't find the email?
        <span on:click={sendEmail} class="cursor-pointer text-socius-primary"
          >Resend verification email</span
        >
        or contact
        <span class="text-gray-700">support@sociusco.com</span>
      </p>

      <p class="mt-4 small-text">
        <span on:click={callAuthLogout} class="cursor-pointer text-gray-700"
          >Logout</span
        >
      </p>
    </div>
  </div>
</div>

<style>
  .small-text {
    font-size: 0.7rem;
  }
</style>
