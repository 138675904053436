<script>
  import { onMount } from 'svelte';
  import {
    createNew,
    sociusApps,
    myApps,
    selectedOrg,
    loading,
  } from '../../utils/store';
  import SociusApp from '../../components/SociusApp.svelte';
  import MyApp from '../../components/MyApp.svelte';
  import { redirect } from '@sveltech/routify';

  import { getAllApps } from '../../utils/utils';
  import Heading from '../../components/Heading.svelte';

  onMount(() => {
    if ($selectedOrg === undefined && !$loading) {
      $createNew = true;
      $redirect('/organizations');
    }

    if (
      $myApps == undefined ||
      $myApps.length == 0 ||
      $sociusApps == undefined ||
      $sociusApps.length == 0
    ) {
      //   console.log('Loading apps...');
      getAllApps();
    }

    $createNew = false;
  });
</script>

{#if !$loading}
  <Heading
    heading="Products"
    subheading="Our business tools allow you to efficiently and seamlessly manage your
    company."
  />

  <div class="mt-8">
    {#each $myApps as app}
      <MyApp {app} />
    {/each}

    {#if $myApps.length > 0 && $sociusApps.length > 0}
      <hr class="my-8" />
      <p class="text-2xl font-bold mb-4 dark:text-white">
        More Socius Products
      </p>
    {/if}

    {#each $sociusApps as app}
      <SociusApp {app} />
    {/each}
  </div>
{/if}
