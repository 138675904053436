<script>
  export let label = '';
  export let value = '';
  export let disabled = false;
  export let placeholder = '';
</script>

{#if label != ''}
  <label for="value" class="text-small text-gray-700 dark:text-gray-400">
    {label}
  </label>
{/if}
<input
  bind:value
  {disabled}
  class:cursor-not-allowed={disabled}
  class:text-gray-500={disabled}
  class:dark:text-gray-300={!disabled}
  class:text-gray-700={!disabled}
  class:placeholder-gray-500={!disabled}
  type="text"
  name="value"
  class="mb-2 outline-none border-transparent border-b border-gray-600 w-full py-2 pr-4 placeholder-gray-300 dark:placeholder-gray-500 text-xs bg-transparent focus:border-b-2 focus:border-socius-secondary transition duration-300 ease-in-out"
  {placeholder}
/>

<style>
  .text-small {
    font-size: 0.65rem;
  }
</style>
