<script lang="ts">
  import {
    user,
    organizations,
    createNew,
    selectedOrg,
    states,
  } from '../../utils/store';
  //   import { getApps } from '../../utils/utils';
  import Organization from '../../components/Organization.svelte';
  import { redirect } from '@sveltech/routify';
  import { onMount } from 'svelte';
  import { getNotificationsContext } from 'svelte-notifications';
  import LogoutModal from '../../components/LogoutModal.svelte';
  import Tooltip from '../../components/Tooltip.svelte';
  import { db } from '../../utils/services';
  import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
  import Input from '../../components/Input.svelte';
  import Select from '../../components/Select.svelte';

  const { addNotification } = getNotificationsContext();

  let logout = false;
  let showTooltip = false;

  let loading = true;
  let name = '';
  let address = '';
  let city = '';
  let state = '';
  let zip = '';
  let repName = $user ? $user.displayName : '';
  let repEmail = $user ? $user.email : '';

  const getOptionLabelStates = (option) => `${option.full} - ${option.short}`;
  const getSelectionLabelStates = (option) => option.short;
  const optionIdentifierStates = 'short';

  function getState(event) {
    // console.log('selected item', event.detail);
    state = event.detail.short;
  }

  function getStarted() {
    let users = [];
    if (repEmail == $user.email) {
      users = [
        {
          createdBy: $user.uid,
          lastUpdatedBy: $user.uid,
          email: repEmail,
        },
      ];
    } else {
      users = [
        {
          createdBy: $user.uid,
          lastUpdatedBy: $user.uid,
          email: repEmail,
        },
        {
          createdBy: $user.uid,
          lastUpdatedBy: $user.uid,
          email: $user.email,
        },
      ];
    }

    const org = {
      lastUpdatedAt: serverTimestamp(),
      lastUpdatedBy: $user.uid,
      owner: $user.uid,
      repName: repName,
      repEmail: repEmail,
      address: address,
      city: city,
      state: state,
      zip: zip,
      name: name,
      users: users,
    };

    addDoc(collection(db, 'organizations'), org)
      .then((res) => {
        console.log('res', res.id);
        // $selectedOrg.id = res.id;
      })
      .catch((err) => {
        console.log(`Error adding org: ${org}`, err);
      });

    // getApps();
    $redirect('/business');
  }

  function redirectToBusiness() {
    if ($selectedOrg) {
      $createNew = false;
      $redirect('/business');
    } else {
      addNotification({
        text: 'Please input your business information before moving on.',
        position: 'bottom-center',
        type: 'warning',
        removeAfter: 2000,
      });
    }
  }

  onMount(() => {
    if (!$organizations || ($organizations && $organizations.length == 0)) {
      $createNew = true;
    }

    loading = false;
  });
</script>

{#if logout}
  <LogoutModal bind:logout />
{/if}

{#if $createNew || loading}
  <!-- <span
    class="fixed right-0 text-xs text-gray-700 md:right-8 lg:right-10 top-8 rounded-full p-2"
    >X</span
  > -->

  <div class="hidden md:inline md:w-1/2">
    <div
      class="relative py-8 flex content-center items-center justify-center min-h-screen-75 h-screen"
    >
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        style="background-image: url(https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80);"
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-50 bg-black"
        />
      </div>
      <div class="relative h-full">
        <div class="flex flex-col h-full">
          <img src="/socius-logo.svg" alt="Socius Logo" class="h-20" />

          <div class="flex-grow" />

          <div class="opacity-50 text-white text-xs">
            Copyright 2021 Socius LLC. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="w-full md:w-1/2 px-16 py-8">
    <div class="mb-8 dark:text-white">
      <div class="flex justify-between">
        <span class="text-5xl font-bold pb-4">Welcome</span>
        <span
          class="flex items-center justify-center rounded-full p-2 hover:text-socius-secondary cursor-pointer text-xs h-6"
          on:click={redirectToBusiness}>X</span
        >
      </div>
      <p class="text-sm">
        We're excited to help you simplify how you do business.
      </p>
      <p class="text-sm">
        Tell us a bit about your business and we'll get started.
      </p>
    </div>

    <div class="">
      <Input label="Business Name" bind:value={name} placeholder="Acme, Co." />
      <Input
        label="Business Address"
        bind:value={address}
        placeholder="308 Negra Arroyo Lane"
      />
    </div>

    <div class="w-full flex gap-4">
      <div class="w-1/3">
        <Input label="City" bind:value={city} placeholder="Albuquerque" />
      </div>

      <div class="w-1/3">
        <div class="border-b border-gray-300 dark:border-gray-600">
          <Select
            label="State"
            items={states}
            showIndicator={true}
            isClearable={false}
            getOptionLabel={getOptionLabelStates}
            getSelectionLabel={getSelectionLabelStates}
            optionIdentifier={optionIdentifierStates}
            bind:selectedValue={state}
            on:notify={getState}
            placeholder="NM"
          />
        </div>
      </div>

      <div class="w-1/3">
        <Input label="Zipcode" bind:value={zip} placeholder="87104" />
      </div>
    </div>

    <hr class="bg-black dark:bg-gray-600 mb-4 mt-6 border-top-2" />

    <div class="">
      <Input
        label="Representative Name"
        bind:value={repName}
        placeholder="George P. Burdell"
      />
      <Input
        label="Representative Email"
        bind:value={repEmail}
        placeholder="gpburdell@email.com"
      />
    </div>

    <div class="mt-8">
      <div class="flex justify-between">
        <button
          class="py-4 px-6 uppercase bg-socius-primary text-white shadow-sm rounded-lg hover:shadow-lg text-xs font-bold"
          on:click={getStarted}
          >Get Started
          <i class="ml-2 fas fa-arrow-right" />
        </button>
        <span class="flex items-center justify-center mx-1 w-6">
          <Tooltip text="Logout" {showTooltip}>
            <i
              class="fa fa-sign-out-alt text-xs text-socius-primary"
              on:click={() => (logout = true)}
              on:mouseenter={() => (showTooltip = true)}
              on:mouseleave={() => (showTooltip = false)}
            />
          </Tooltip>
        </span>
      </div>
    </div>
  </div>
{:else}
  <div class="w-full">
    <div class="flex flex-wrap -mx-1 lg:-mx-4">
      {#each $organizations as org}
        <Organization {org} />
      {/each}
    </div>
  </div>
{/if}

<style>
</style>
