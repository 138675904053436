<script>
  import { isActive } from '@sveltech/routify';

  export let icon = '';
  export let heading = '';
  export let route = '';
</script>

<a
  href={route}
  class:bg-gray-100={$isActive(route)}
  class:text-socius-secondary={$isActive(route)}
  class:text-gray-700={!$isActive(route)}
  class:dark:bg-gray-700={$isActive(route)}
  class:dark:text-socius-secondary={$isActive(route)}
  class:dark:text-gray-300={!$isActive(route)}
  class:py-4={$isActive(route)}
  class="w-full pl-4 pr-4 lg:pr-6 py-2 hidden md:flex text-xs lg:text-sm flex items-center justify-start hover:bg-gray-100 hover:text-socius-secondary dark:hover:bg-gray-700 dark:hover:text-socius-secondary"
>
  <div class="w-2 lg:w-8 flex items-center justify-center">
    <i class={icon} />
  </div>
  <span class="pl-2"> {heading} </span>
</a>
